* {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-rendering: geometricPrecision;
}

@font-face {
    font-family: Circe;
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: url('./files/Circe-Regular.woff2') format('woff2');
}

@font-face {
    font-family: Circe;
    font-display: auto;
    font-style: normal;
    font-weight: bold;
    src: url('./files/Circe-Bold.woff2') format('woff2');
}

@font-face {
    font-family: IBMPlexSans;
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: url('./files/IBMPlexSans-Regular.woff2') format('woff2');
}

@font-face {
    font-family: IBMPlexSans;
    font-display: auto;
    font-style: normal;
    font-weight: bold;
    src: url('./files/IBMPlexSans-Medium.woff2') format('woff2');
}
